<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Form Response Portal</h1>

      <div class="mt-14">
        <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2 mx-auto">
          <!-- Column Content -->
          <div
            class="w-full border-2 drop-shadow-sm border-gray-100 flex flex-col bg-white rounded-lg overflow-hidden cursor-pointer"
          >
            <div class="border-t border-gray-200 p-5">
              <p class="font-medium mt-2 text-sm">
                Form Responses
              </p>
              <p class="text-xs">
                Click on cards to see detailed responses
              </p>
              <div
                class="border-2 border-gray-50 drop-shadow-2xl rounded-md cursor-pointer hover:bg-blue-300 hover:bg-opacity-25 hover:rounded-md p-3 mt-5"
                @click="
                  $router.push({
                    name: 'SimManagement',
                  })
                "
              >
                <p class="font-medium text-xs">
                  SIM Management
                </p>
                <p class="text-xs">{{ totalSimManagement }} Responses</p>
              </div>
              <div
                class="border-2 border-gray-50 drop-shadow-2xl rounded-md cursor-pointer hover:bg-blue-300 hover:bg-opacity-25 hover:rounded-md p-3 mt-5"
                @click="
                  $router.push({
                    name: 'SupportDeployment',
                  })
                "
              >
                <p class="font-medium text-xs">
                  Support Deployment
                </p>
                <p class="text-xs">{{ totalSupportDeployment }} Responses</p>
              </div>
              <div
                class="border-2 border-gray-50 drop-shadow-2xl rounded-md cursor-pointer hover:bg-blue-300 hover:bg-opacity-25 hover:rounded-md p-3 mt-5"
                @click="
                  $router.push({
                    name: 'MerchantVisitation',
                  })
                "
              >
                <p class="font-medium text-xs">
                  Merchant Visitation
                </p>
                <p class="text-xs">{{ totalMerchantVisitations }} Responses</p>
              </div>
              <div
                class="border-2 border-gray-50 drop-shadow-2xl rounded-md cursor-pointer hover:bg-blue-300 hover:bg-opacity-25 hover:rounded-md p-3 mt-5"
                @click="
                  $router.push({
                    name: 'RetrievalResponse',
                  })
                "
              >
                <p class="font-medium text-xs">
                  Retrieval
                </p>
                <p class="text-xs">{{ totalRetrievalResponse }} Responses</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      totalMerchantVisitations: 0,
      totalRetrievalResponse: 0,
      totalSimManagement: 0,
      totalSupportDeployment: 0,
    };
  },
  computed: {},
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {},
  created() {
    this.fetchMerchantVisitationsRecords();
    this.fetchRetrievalResponseRecords();
    this.fetchSupportDeploymentRecords();
    this.fetchSimManagementRecords();
  },
  methods: {
    async fetchMerchantVisitationsRecords() {
      this.isLoading = true;
      let response = await this.$store.dispatch("FETCH_MERCHANT_VISITATION");
      this.totalMerchantVisitations = response.data.length;
      this.totalMerchantVisitations +=
        this.totalMerchantVisitations > 99 ? "+" : "";
      this.isLoading = false;
    },
    async fetchRetrievalResponseRecords() {
      this.isLoading = true;
      let response = await this.$store.dispatch("FETCH_RETRIEVAL_RESPONSES");
      this.totalRetrievalResponse = response.data.length;
      this.totalRetrievalResponse +=
        this.totalRetrievalResponse > 99 ? "+" : "";
      this.isLoading = false;
    },
    async fetchSupportDeploymentRecords() {
      this.isLoading = true;
      let response = await this.$store.dispatch("FETCH_SUPPORT_DEPLOYMENT");
      this.totalSupportDeployment = response.data.length;
      this.totalSupportDeployment +=
        this.totalSupportDeployment > 99 ? "+" : "";
      this.isLoading = false;
    },
    async fetchSimManagementRecords() {
      this.isLoading = true;
      let response = await this.$store.dispatch("FETCH_SIM_MANAGEMENT");
      this.totalSimManagement = response.data.length;
      this.totalSimManagement += this.totalSimManagement > 99 ? "+" : "";
      this.isLoading = false;
    },
  },
};
</script>
